<template>
  <b-table-filter
    :items="rows"
    :fields="fields"
    title="Báo Cáo Quản Lý Lớp"
    :perPage="perPage"
    :pageLength="pageLength"
    :isBusy="busy"
    :totalRows="totalRow"
  >
    <template #filterTable>
      <td style="width: 15%">
        <b-form-datepicker placeholder="Từ Ngày" size="sm" v-model="fromDate" />
      </td>
      <td style="width: 15%">
        <b-form-datepicker placeholder="Đến Ngày" size="sm" v-model="toDate" />
      </td>
      <td>
        Nếu không chọn Ngày Sẽ Lấy 5 ngày gần Nhất
      </td>
      <td>
        <div style="float:right">
          <b-button variant="warning"  size="sm"@click="onSubmitFilter" style="margin-right: 5px"><feather-icon icon="SearchIcon" /> Xem Báo Cáo</b-button>
        </div>
      </td>
    </template>
  </b-table-filter>
</template>

<script>
import {
  BFormDatepicker, BButton, 
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import BTableFilter from '@/@core/components/datatable/BTableFilter.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: {
    BFormDatepicker,
    BButton,
    BTableFilter,
    FeatherIcon,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perPage: 10,
      busy: false,
      totalRow: 1,
      pageLength: 10,
      dir: false,
      fields: [
        {
          label: 'STT',
          key: 'id',
        },
        {
          label: 'Lớp Học',
          key: 'className',
        },
        {
          label: 'Giáo Viên',
          key: 'teacher',
        },
        {
          label: 'Số Học Sinh',
          key: 'studentCount',
        },
        {
          label: 'Quản Lý',
          key: 'managerAccount',
        },
        {
          label: 'Ngày Tạo',
          key: 'createdAt',
        },
      ],
      rows: [],
      fromDate: null,
      toDate: null,
    }
  },
  computed: {
  },
  created() {
  },
  methods: {
    exportToPrint(e) {
      const printContents = document.getElementById(e).innerHTML
      const originalContents = document.body.innerHTML
      document.body.innerHTML = printContents
      window.print()
      document.body.innerHTML = originalContents
      return true
    },
    onSubmitFilter() {
      this.busy = false
      console.log('onSubmitFilter', this.fromDate, this.toDate)
      this.rows = [
        {
          id: 1,
          className: 'Danh Sinh',
          teacher: '096-998-5002',
          studentCount: 90000,
          managerAccount: 'Đã Xác Nhận',
          createdAt: '20/03/2019',
        },
      ]
    },
    onReset() {
      this.fromDate = null
      this.toDate = null
    },
  },
}
</script>
